.toolkit {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    position: relative;
    margin-bottom: -5rem;

}

.toolkit>.heading {
    margin-bottom: 15rem;
}

.toolbox-container {
    text-align: center;
    position: relative;
    transform: scale(1.1);
    width: 350px;
    padding: 1.5rem;
    margin-bottom: 20rem;

}

.pointer {
    cursor: pointer;
}

.codes-text {
    font-family: orbitron;
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;

}

.codes-text2 {
    font-family: orbitron;
    position: absolute;
    text-align: center;
    width: 160px;
    bottom: 40px;
    left: 119px;
    padding: 0.5rem;
    border-radius: 1rem;
    background-color: rgb(255, 255, 51);
    font-weight: bolder;
    box-shadow: 4px 4px 4px #888888;
    color: black;
    text-shadow: 2px 2px 8px white;
    font-size: 1.5rem;
    z-index: 1;
    border: none;
    cursor: pointer;
}

.moveUp {
    width: 350px;
    top: -100px;
    animation: moveUp 0.5s linear forwards;
}

.codes {
    font-family: orbitron;
    opacity: 1;
    font-size: 15rem;
    color: yellow;
    text-shadow: 2px 2px 8px white;
    position: absolute;
    padding: 0;
    margin: 0;
    top: -25px;

}

.flash {
    animation: flash 1s linear infinite;
}

.opaque {
    opacity: 0.3;
}

.codes:first-child {
    top: -40px;
    left: -50px;
}

.codes:nth-child(2) {
    top: -40px;
    right: -50px;
}


.toolbox-code-icon1, .toolbox-code-icon2 {
    position: absolute;
    top: 62%;
    left: 50%;
    color: white;
    font-size: 5rem;
    transform: translateX(-50%) rotate(0deg);
    opacity: 0;
    text-align: center;
    overflow: visible;
}

.toolbox-code-icon2 {
    color: rgb(255, 255, 51);
}

.icon-text {
    font-family: orbitron;
    /* font-family: 'Righteous', cursive; */
    font-size: 1.1rem;
    margin: 0;
    margin-top: -0.5rem;
    padding: 0;
    opacity: 0;
}

.appear3 {
    animation: appear3 0.5s linear 3s forwards;
}

.emerge1 {
    animation: emerge1 1s linear forwards;
}

.emerge2 {
    animation: emerge2 1s linear 0.4s forwards;
}

.emerge3 {
    animation: emerge3 1s linear 0.8s forwards;
}

.emerge4 {
    animation: emerge4 1s linear 1.2s forwards;
}

.emerge5 {
    animation: emerge5 1s linear 1.6s forwards;
}

.emerge6 {
    animation: emerge6 1s linear 1.8s forwards;
}

.emerge7 {
    animation: emerge7 1s linear 1.4s forwards;
}

.emerge8 {
    animation: emerge8 1s linear 1s forwards;
}

.emerge9 {
    animation: emerge9 1s linear 0.6s forwards;
}

.emerge10 {
    animation: emerge10 1s linear 0.2s forwards;
}

.toolbox {
    font-size: 12rem;
    transform: rotate(0deg);
    color: white;

}

.shake {
    animation: shake 0.1s 20 alternate;
}

.disappear {
    animation: disappear 0.1s forwards;
}

.toolbox-text {
    color: white;
    font-size: 2rem;
}

@keyframes shake {
    0% {
        transform: rotate(0deg)
    }

    25% {
        transform: rotate(5deg)
    }

    75% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(-5deg)
    }
}

@keyframes emerge1 {
    0% {
        transform: translateX(-50%) rotate(0deg);
        opacity: 0;
    }

    25% {
        transform: translateX(-50%) translateY(-300px) rotate(-180deg);
        opacity: 1;
    }

    50% {
        transform: translate(-250px, -250px) rotate(-360deg);
        opacity: 1;
    }

    75% {
        transform: translate(-300px, 0px) rotate(-540deg);
        opacity: 1;
    }

    100% {
        transform: translate(-50%, 150px) rotate(-720deg);
        opacity: 1;
    }
}


@keyframes emerge2 {
    0% {
        transform: translateX(-50%) rotate(0deg);
        opacity: 0;
    }

    25% {
        transform: translateX(-50%) translateY(-300px) rotate(-180deg);
        opacity: 1;
    }

    50% {
        transform: translate(-250px, -250px) rotate(-360deg);
        opacity: 1;
    }

    75% {
        transform: translate(-300px, 0px) rotate(-540deg);
        opacity: 1;
    }

    100% {
        transform: translate(-175px, 125px) rotate(-720deg);
        opacity: 1;
    }
}

@keyframes emerge3 {
    0% {
        opacity: 0;
        transform: translateX(-50%) rotate(0deg);
    }

    25% {
        transform: translateX(-50%) translateY(-300px) rotate(-180deg);
        opacity: 1;
    }

    50% {
        opacity: 1;
        transform: translate(-250px, -250px) rotate(-360deg);
    }

    75% {
        transform: translate(-300px, 0px) rotate(-540deg);
        opacity: 1;
    }

    100% {
        transform: translate(-250px, 0px) rotate(-720deg);
        opacity: 1;
    }
}

@keyframes emerge4 {
    0% {
        transform: translateX(-50%) rotate(0deg);
        opacity: 0;
    }

    25% {
        transform: translateX(-50%) translateY(-300px) rotate(-180deg);
        opacity: 1;
    }

    50% {
        transform: translate(-250px, -250px) rotate(-360deg);
        opacity: 1;
    }

    75% {
        transform: translate(-300px, -200px) rotate(-540deg);
        opacity: 1;
    }

    100% {
        transform: translate(-250px, -150px) rotate(-720deg);
        opacity: 1;
    }
}

@keyframes emerge5 {
    0% {
        transform: translateX(-50%) rotate(0deg);
        opacity: 0;
    }


    25% {
        transform: translateX(-50%) translateY(-300px) rotate(-180deg);
        opacity: 1;
    }

    50% {
        transform: translate(-125px, -290px) rotate(-360deg);
        opacity: 1;
    }

    75% {
        transform: translate(-150px, -280px) rotate(-540deg);
        opacity: 1;
    }

    100% {
        transform: translate(-175px, -275px) rotate(-720deg);
        opacity: 1;
    }
}

@keyframes emerge6 {
    0% {
        transform: translateX(-50%) rotate(0deg);
        opacity: 0;
    }

    100% {
        transform: translate(-50%, -300px) rotate(720deg);
        opacity: 1;
    }
}

@keyframes emerge7 {
    0% {
        transform: translateX(-50%) rotate(0deg);
        opacity: 0;
    }

    25% {
        transform: translateX(-50%) translateY(-300px) rotate(180deg);
        opacity: 1;
    }

    50% {
        transform: translate(25px, -290px) rotate(360deg);
        opacity: 1;
    }

    75% {
        transform: translate(50px, -280px) rotate(540deg);
        opacity: 1;
    }

    100% {
        transform: translate(100px, -275px) rotate(720deg);
        opacity: 1;
    }
}

@keyframes emerge8 {
    0% {
        transform: translateX(-50%) rotate(0deg);
        opacity: 0;
    }

    25% {
        transform: translateX(-50%) translateY(-300px) rotate(180deg);
        opacity: 1;
    }

    50% {
        transform: translate(150px, -250px) rotate(360deg);
        opacity: 1;
    }

    75% {
        transform: translate(200px, -200px) rotate(540deg);
        opacity: 1;
    }


    100% {
        transform: translate(175px, -150px) rotate(720deg);
        opacity: 1;
    }
}

@keyframes emerge9 {
    0% {
        transform: translateX(-50%) rotate(0deg);
        opacity: 0;
    }

    25% {
        transform: translateX(-50%) translateY(-300px) rotate(180deg);
        opacity: 1;
    }

    50% {
        transform: translate(150px, -250px) rotate(360deg);
        opacity: 1;
    }

    75% {
        transform: translate(200px, 0px) rotate(540deg);
        opacity: 1;
    }

    100% {
        transform: translate(175px, 0px) rotate(720deg);
        opacity: 1;
    }
}

@keyframes emerge10 {
    0% {
        transform: translateX(-50%) rotate(0deg);
        opacity: 0;
    }

    25% {
        transform: translateX(-50%) translateY(-300px) rotate(180deg);
        opacity: 1;

    }

    50% {
        transform: translate(175px, -250px) rotate(360deg);
        opacity: 1;

    }

    75% {
        transform: translate(225px, 0px) rotate(540deg);
        opacity: 1;

    }

    100% {
        transform: translate(100px, 125px) rotate(720deg);
        opacity: 1;
    }
}

@keyframes appear3 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

}

@keyframes flash {

    0% {
        opacity: 0;
    }


    100% {
        opacity: 1;
    }
}

@keyframes moveUp {
    0% {
        top: -100px;
        opacity: 1;
    }

    100% {
        top: -300px;
    }
}

@keyframes disappear {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }

}

@media (max-width: 900px) {
    .toolkit>.heading {
        margin-bottom: 11rem;
    }

    .toolbox-container {
        transform: scale(1);
        margin-bottom: 15rem;

    }

}

@media (max-width: 700px) {
    .toolkit>.heading {
        margin-bottom: 7rem;
    }

    .toolbox-container {
        transform: scale(0.8);
        margin-bottom: 10rem;
    }


    .icon-text {
        transform: scale(1.3);
    }

}

@media (max-width: 550px) {
    .codes {
        display: none;
    }

}



@media (max-width: 500px) {
    .toolbox-container {
        transform: scale(0.7);
        margin-bottom: 8rem;
    }

    .toolkit>.heading {
        margin-bottom: 5rem;
    }

    .icon-text {
        transform: scale(1.4);
    }




}

@media (max-width: 380px) {
    .toolbox-container {
        transform: scale(0.6);
        margin-bottom: 6rem;
    }

    .toolkit>.heading {
        margin-bottom: 4rem;
    }


}