/* righteous-regular - latin */
@font-face {
    font-family: 'Righteous';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('../public/fonts/righteous-v13-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../public/fonts/righteous-v13-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* orbitron-regular - latin */
@font-face {
    font-family: 'Orbitron';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('../public/fonts/orbitron-v25-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../public/fonts/orbitron-v25-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body {
    margin: 0;
}

.heading {
    color: #ffff33;
    font-family: 'Orbitron', sans-serif;
    font-size: 3rem;
    text-align: center;
    text-transform: uppercase;
    padding-top: 6rem;
    animation: colorChange 1s linear alternate infinite;
}

#projects-heading {
    margin-bottom: -2rem;
}

.sub-heading {
    color: #ffffff;
    font-family: 'Orbitron', sans-serif;
    font-size: 2rem;
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 1.5rem;
    text-transform: uppercase;
    animation: colorChange 1s linear alternate infinite;
}

@media (max-width: 700px) {
    .heading {
        font-size: 2rem;
    }

    .sub-heading {
        font-size: 1.5rem;
    }
}