.footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgb(255, 255, 51);
    height: 40px;
    color: black;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    font-size: 1.4rem;
    font-family: orbitron;
}