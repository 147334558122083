.projects {
    position: relative;
    overflow: hidden;
    margin-bottom: 2rem;
    margin: 5% 5%;
    margin-bottom: -5rem;
}

.code-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 4rem;
    padding: 5rem;
}

.projects-block {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    color: white;
    gap: 3rem;
    padding: 4rem;

}


.project-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: 0.3s;
    clip-path: polygon(0 1%, 100% 0, 100% 100%, 0% 100%);



}

.img-container {
    position: relative;
    height: 250px;
    width: 100%;
    cursor: pointer;

}

.project-card {
    font-family: orbitron;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: black;
    text-shadow: 2px 2px 8px white;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    opacity: 0;
    transition: all 0.3s;

}

.project-name {
    margin: 0.5rem;
    font-size: 1.6rem;
    text-align: center;
}

.project-description {
    margin: 0.5rem;
    font-size: 1.4rem;
    text-align: center;


}

@media (max-width: 1400px) {
    .project-description {
        font-size: 1.3rem;

    }

}


@media (max-width: 1300px) {
    .projects-block {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}



@media (max-width: 900px) {

    .learn-more-text {
        font-size: 1.2rem;

    }
}

@media (max-width: 800px) {
    .projects-block {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        padding: 2rem;

    }

}



@media (max-width: 450px) {

    .project-name {
        font-size: 1.4rem;
    }

    .projects-block {
        padding: 0.5rem;
    }
}

.learn-more {
    margin-top: -2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-family: orbitron;
    gap: 0.5rem;
    color: white;
    text-shadow: 2px 2px 8px black;
}

.learn-more-text {
    margin-top: 0.5rem;
    font-size: 1.4rem;
    font-family: orbitron;
    background-color: transparent;
    border: none;
    color: white;
    text-shadow: 2px 2px 8px black;
    cursor: pointer;
}

.code {
    opacity: 1;
    font-size: 3rem;
    color: yellow;
    text-shadow: 2px 2px 8px black;
    animation: flash 1s linear infinite;
    font-weight: 800;
}

.glass-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
}

.glass {
    font-size: 5rem;
    color: yellow;
    opacity: 1;
}

.img-container:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 51, 1);
    transition: all 0.3s;
    z-index: 1;
    clip-path: polygon(100% 90%, 0 100%, 0 100%, 100% 100%);


}

.img-container:hover:before {
    clip-path: polygon(0 1%, 100% 0, 100% 100%, 0% 100%);
    background-color: rgba(255, 255, 51, 0.6);

}

.img-container:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 51, 1);
    transition: all 0.3s;
    z-index: 1;
    clip-path: polygon(0 0, 0 0, 0 10%, 100% 0);
}

.img-container:hover:after {
    clip-path: polygon(0 1%, 100% 0, 100% 100%, 0% 100%);
    background-color: rgba(255, 255, 51, 0.6);

}

.img-container:hover .project-card {
    opacity: 1;
}

.img-container:hover .glass-container {
    opacity: 0;
}


@keyframes flash {

    0% {
        opacity: 0;
    }


    100% {
        opacity: 1;
    }
}