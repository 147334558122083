.sidebar-header-container {
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: rgb(255, 255, 51);
    color: black;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    height: 40px;
    z-index: 10;
    font-size: 1.4rem;
    line-height: 2rem;
    font-family: orbitron;
}

.logo {
    font-family: 'Righteous', cursive;
    color: white;
    text-shadow: 2px 2px 8px black;
    font-size: 1.5rem;
}

.logo2 {
    color: black;
    text-shadow: 2px 2px 8px white;
    font-size: 1.5rem;

}

.sidebar-header-container>a {
    text-decoration: none;
}

.sidebar-header-container>a:visited {
    text-decoration: none;
}


a:visited {
    color: black !important;
}

.menu-icon {
    font-size: 2rem;
    margin-right: 0.5rem;
    transition: all 0.5s;
    cursor: pointer;
}

.menu-icon:hover {
    transform: rotate(90deg);
}

[data-aos="nav-animation"] {
    opacity: 0;
    position: relative;
}

[data-aos="nav-animation"].aos-animate {
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
}

@media (min-width: 900px) {
    .sidebar-header-container {
        display: none;
    }
}